import React from 'react';
import { YouTubeVideo } from './types';

interface VideoCardProps {
  video: YouTubeVideo;
}

const VideoCard = ({ video }: VideoCardProps) => {
  return (
    <a
      href={`https://www.youtube.com/watch?v=${video.id.videoId}`}
      target="_blank"
      rel="noopener noreferrer"
      className="bg-white rounded-2xl shadow-lg overflow-hidden transform transition-transform hover:scale-105"
    >
      <img
        src={video.snippet.thumbnails.medium.url}
        alt={video.snippet.title}
        className="w-full aspect-video object-cover"
      />
      <div className="p-6">
        <h3 className="font-medium text-lg mb-2">{video.snippet.title}</h3>
        <p className="text-sm text-gray-500">
          {new Date(video.snippet.publishedAt).toLocaleDateString()}
        </p>
      </div>
    </a>
  );
};

export default VideoCard;