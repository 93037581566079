import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { YouTubeVideo, SecretResponse } from "./types";

const CHANNEL_ID = "UCsvIQZwGwxNDIICaAvQg0kA";

export const useYouTubeVideos = () => {
  return useQuery({
    queryKey: ["youtubeVideos"],
    queryFn: async () => {
      try {
        console.log("Starting YouTube videos fetch process");
        
        // Fetch API key from Supabase
        const { data: secretData, error: secretError } = await supabase
          .rpc('get_secret', { name: 'YOUTUBE_API_KEY' }) as unknown as SecretResponse;
        
        if (secretError) {
          console.error("Error fetching YouTube API key:", secretError);
          throw new Error(`Failed to get API key: ${secretError.message}`);
        }

        if (!secretData?.YOUTUBE_API_KEY) {
          console.error("No YouTube API key found in secrets");
          throw new Error("YouTube API key is missing");
        }

        console.log("Successfully retrieved API key, proceeding with YouTube API call");

        const apiUrl = `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${CHANNEL_ID}&maxResults=3&order=date&type=video&key=${secretData.YOUTUBE_API_KEY}`;
        
        const response = await fetch(apiUrl);
        console.log("YouTube API response status:", response.status);
        
        if (!response.ok) {
          const errorData = await response.json();
          console.error("YouTube API error response:", errorData);
          throw new Error(errorData.error?.message || `YouTube API error: ${response.status}`);
        }
        
        const responseData = await response.json();
        console.log("YouTube API response received, number of items:", responseData.items?.length);
        
        if (!responseData.items || responseData.items.length === 0) {
          console.log("No videos found in YouTube API response");
          return [];
        }
        
        return responseData.items as YouTubeVideo[];
      } catch (error) {
        console.error("Error in useYouTubeVideos:", error);
        throw error;
      }
    },
    retry: 1,
    staleTime: 300000, // Cache for 5 minutes
  });
};