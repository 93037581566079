import React from 'react';
import VideoCard from './youtube/VideoCard';
import LoadingSkeleton from './youtube/LoadingSkeleton';
import SectionHeader from './youtube/SectionHeader';
import { useYouTubeVideos } from './youtube/useYouTubeVideos';
import { useToast } from "@/hooks/use-toast";

const YouTubeSection = () => {
  const { toast } = useToast();
  const { data: videos, isLoading, error } = useYouTubeVideos();

  React.useEffect(() => {
    if (error) {
      console.error("YouTube section error:", error);
      toast({
        title: "Error loading videos",
        description: "Please try again later",
        variant: "destructive",
      });
    }
  }, [error, toast]);

  if (error) {
    return (
      <section className="py-24 bg-warm">
        <div className="container mx-auto px-6">
          <SectionHeader />
          <div className="text-center text-primary-muted">
            Unable to load videos at this time
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="py-24 bg-warm">
      <div className="container mx-auto px-6">
        <SectionHeader />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {isLoading ? (
            <LoadingSkeleton />
          ) : videos?.length ? (
            videos.map((video) => (
              <VideoCard key={video.id.videoId} video={video} />
            ))
          ) : (
            <div className="col-span-3 text-center text-primary-muted">
              No videos available
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default YouTubeSection;