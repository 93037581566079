
import React from 'react';

const SectionHeader = () => {
  return (
    <div className="text-center mb-16">
      <span className="inline-block px-4 py-1.5 mb-6 text-sm font-medium bg-white rounded-full">
        Recent Videos
      </span>
      <h2 className="text-4xl font-avenir">Latest Content</h2>
    </div>
  );
};

export default SectionHeader;
