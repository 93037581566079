
import { useEffect } from "react";
import Navigation from "@/components/Navigation";
import Hero2 from "@/components/Hero2";
import AboutBookSection from "@/components/AboutBookSection";
import TestimonialsSection from "@/components/TestimonialsSection";
import NewsletterSection from "@/components/NewsletterSection";
import YouTubeSection from "@/components/YouTubeSection";
import Footer from "@/components/Footer";

const Home2 = () => {
  useEffect(() => {
    document.title = "Sharks, Snakes & Business Partners | Cody McLain";
    
    // Add metadata for better SEO and crawler access
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", 
        "Learn how to identify red flags, build trust, and protect yourself in business with Sharks, Snakes & Business Partners by Cody McLain."
      );
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = "Learn how to identify red flags, build trust, and protect yourself in business with Sharks, Snakes & Business Partners by Cody McLain.";
      document.head.appendChild(meta);
    }
  }, []);

  return (
    <div className="min-h-screen bg-background">
      <Navigation />
      <Hero2 />
      <AboutBookSection />
      <TestimonialsSection />
      <YouTubeSection />
      <NewsletterSection />
      <Footer />
    </div>
  );
};

export default Home2;
